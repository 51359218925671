import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  RunServiceRequest,
  useRunServiceMutation,
} from "../../../../api/endpoints/superApi";
import {
  RunServiceRequest as RunKuberServiceRequest,
  useRunServiceMutation as useRunKuberServiceMutation,
} from "../../../../api/endpoints/kuberApi";
import { Button, Alert, Collapse, Grid } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

import InferenceConfigParams from "./inferenceConfigParams";
import { setState } from "./talimInferenceSlice";
import {
  JobItem,
  createJobItem,
} from "../../../../../components/Jobs/jobUtils";
import { writeJsonToS3 } from "../../../../../components/S3/S3Utils";

// --------------------------------------------------------------------------------------------------
interface ApiFileMetaData {
  path: string;
  data_type: string | null;
  drillhole_column_name: string | null;
  interval_from_column_name: string | null;
  interval_to_column_name: string | null;
  interval_depth_column_name: string | null;
  scatter_plots: string[];
  crosstab_plots: string[];
  binning_plots: string[];
}

interface ApiModelMetaData {
  source: string;
  source_type: string;
}

// --------------------------------------------------------------------------------------------------
interface ApiRequest {
  project_name: string;
  environment: string;
  // job_item: JobItem;
  dataset_info: ApiFileMetaData;
  model_info: ApiModelMetaData;
  task_type: string;
  s3_save_path: string | null;
  // litholens_save_path_type: "s3" | "logsAPI" | null;
}

// --------------------------------------------------------------------------------------------------
interface InferenceRequestProps {
  bucketName: string;
  pathPrefix: string;
}

// --------------------------------------------------------------------------------------------------
const InferenceRequest: React.FC<InferenceRequestProps> = ({
  bucketName,
  pathPrefix,
}) => {
  const talim_inference = useAppSelector(
    (state) => state.talim.talimInferenceSlice
  );
  const dispatch = useAppDispatch();

  const maxDimensions =
    talim_inference.filesInfo[talim_inference.selectedReferenceFile]?.tableData
      .columns.length - 3;

  // --------------------------------------------------------------------------------------------------
  // Job Item & API Request

  const auth = useAppSelector((state) => state.auth);
  const projects = useAppSelector((state) => state.projects);

  // const url: string = window.location.href;
  // const company: string = url.split("/").filter(Boolean)[2];
  // const project: string = url.split("/").filter(Boolean)[3];
  // const projectName: string = company + "_" + project;
  const company = projects.selectedProject?.company || "company";
  const project = projects.selectedProject?.id || "project";
  const projectName =
    projects.selectedProject?.litholensName || "company_project";
  const type: string = "logs.inference";
  const createdBy = auth.user?.username || "superapi";

  const jobItem = createJobItem(company, project, type, createdBy);
  const [lastJobID, setLastJobID] = useState<string | null>(null);
  const env = process.env.REACT_APP_ENV || "test";

  // --------------------------------------------------------------------------------------------------
  const [apiRequest, setApiRequest] = useState<ApiRequest>({
    dataset_info: {} as ApiFileMetaData,
    model_info: {} as ApiModelMetaData,
    // job_item: jobItem,
    project_name: projectName,
    environment: env,
    task_type: talim_inference.task_type,
    s3_save_path: talim_inference.s3_save_name
      ? "s3://" +
        bucketName +
        "/" +
        pathPrefix +
        "/data/" +
        talim_inference.s3_save_name +
        ".csv"
      : null,
  });

  // --------------------------------------------------------------------------------------------------
  // Send API Request
  const [runServiceMutation, runServiceMutationResult] =
    useRunServiceMutation();

  const [runKuberServiceMutation, runKuberServiceMutationResult] =
    useRunKuberServiceMutation();

  const handleSubmit = async (requestData: RunServiceRequest) => {
    runServiceMutation(requestData);
    const fixedItem = requestData.job_item as JobItem;
    setLastJobID(fixedItem.id);
    const pendingMessage = { status: "requested" };
    writeJsonToS3(
      bucketName,
      pathPrefix + "/responses/inference/" + jobItem.id + ".json",
      pendingMessage
    );
  };

  const handleKuberSubmit = async (requestData: RunServiceRequest) => {
    runKuberServiceMutation(requestData);
    const fixedItem = requestData.job_item as JobItem;
    setLastJobID(fixedItem.id);
  };

  const log = (type: any) => console.log.bind(console, type);

  // --------------------------------------------------------------------------------------------------
  const [open, setOpen] = useState(false);
  const handleAlertClose = (event: SyntheticEvent) => {
    setOpen(false);
  };

  useEffect(() => {
    var newBaseStructuresInfo = {} as ApiFileMetaData;
    Object.values(talim_inference.filesInfo).forEach((element) => {
      newBaseStructuresInfo = {
        path: "s3://" + bucketName + "/" + element.fileKey,
        data_type: element.metaData.selectedType,
        drillhole_column_name: element.metaData.drillhole,
        interval_from_column_name: element.metaData.begin,
        interval_to_column_name: element.metaData.end,
        interval_depth_column_name: element.metaData.pointDepth,
        scatter_plots: element.scatter_plots,
        crosstab_plots: element.crosstab_plots,
        binning_plots: element.binning_plots,
      };
    });

    var newBaseModelInfo = {} as ApiModelMetaData;
    Object.values(talim_inference.modelsInfo).forEach((element) => {
      newBaseModelInfo = {
        source: "s3://" + bucketName + "/" + element.source,
        source_type: "s3",
      };
    });
    // var modifiedLogDescripitveName = "new_log";
    // if (typeof talim_inference.descriptive_log_name === "string") {
    //   modifiedLogDescripitveName = talim_inference.descriptive_log_name;
    // }
    setApiRequest({
      ...apiRequest,
      dataset_info: newBaseStructuresInfo,
      model_info: newBaseModelInfo,
      task_type: talim_inference.task_type,
      s3_save_path: talim_inference.s3_save_name
        ? "s3://" +
          bucketName +
          "/" +
          pathPrefix +
          "/data/" +
          talim_inference.s3_save_name +
          ".csv"
        : null,
      // register_to_litholens: talim_inference.register_to_litholens,
    });
  }, [talim_inference]);

  useEffect(() => {
    // if (runServiceMutationResult?.isSuccess) {
    //   alert("API request has been sent successfully!");
    // } else {
    //   if (runServiceMutationResult?.isError) {
    //     alert("API request has been encountered an error!");
    //   }
    // }
    setOpen(true);
  }, [runServiceMutationResult]);

  // --------------------------------------------------------------------------------------------------
  return (
    <div style={{ display: "flex", flexDirection: "column", margin: "20px" }}>
      <InferenceConfigParams />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            onClick={(e) =>
              handleSubmit({
                service: "ll_run_talim_inference",
                request_body: apiRequest,
                job_item: jobItem,
                environment: env,
              } as RunServiceRequest)
            }
            onError={log("errors")}
            disabled={runServiceMutationResult.isLoading}
          >
            Send Request
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            onClick={(e) =>
              handleKuberSubmit({
                service: "talim_inference/inference",
                request_body: apiRequest,
                job_item: jobItem,
                environment: env,
              } as RunServiceRequest)
            }
            onError={log("errors")}
            disabled={runKuberServiceMutationResult.isLoading}
          >
            Send Request (Beta)
          </Button>
        </Grid>
      </Grid>
      <Collapse in={open}>
        {(runServiceMutationResult?.isSuccess ||
          runServiceMutationResult?.isError) && (
          <Alert
            icon={<CheckIcon fontSize="inherit" />}
            severity={runServiceMutationResult?.isSuccess ? "success" : "error"}
            onClose={handleAlertClose}
          >
            {runServiceMutationResult?.isSuccess
              ? "Request sent successfully! Your request ID is " + jobItem.id
              : "Request Sending has been ecnountered an error!"}
          </Alert>
        )}
      </Collapse>
      <Collapse in={open}>
        {(runKuberServiceMutationResult?.isSuccess ||
          runKuberServiceMutationResult?.isError) && (
          <Alert
            icon={<CheckIcon fontSize="inherit" />}
            severity={
              runKuberServiceMutationResult?.isSuccess ? "success" : "error"
            }
            onClose={handleAlertClose}
          >
            {runKuberServiceMutationResult?.isSuccess
              ? "Job done successfully! Your request ID is " + lastJobID
              : "Request Sending has been ecnountered an error!"}
          </Alert>
        )}
      </Collapse>
      {/* <pre>{JSON.stringify(apiRequest, null, 2)}</pre> */}
    </div>
  );
};

export default InferenceRequest;
